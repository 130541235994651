import { FC } from 'react';
import {
  Box,
  Link,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Instagram, Mail as MailIcon } from '@mui/icons-material';
import { copyTextToClipboard } from '../../utils/clipboard';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Stack
        component="section"
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ my: 8 }}
      >
        <Tooltip title={process.env.REACT_APP_CONTACT_EMAIL}>
          <IconButton
            onClick={() =>
              copyTextToClipboard(process.env.REACT_APP_CONTACT_EMAIL).then(
                () =>
                  enqueueSnackbar(t('home.contact.email-copied-to-clipboard'), {
                    variant: 'success',
                  })
              )
            }
            sx={{
              p: 2,
              backgroundColor: 'transparent',
              borderRadius: '50% 50% 0 50%',
              transform: 'rotate(45deg)',
            }}
          >
            <MailIcon
              htmlColor="white"
              sx={{ transform: 'rotate(-45deg)', fontSize: '2rem' }}
            />
          </IconButton>
        </Tooltip>

        <Link
          sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
          href="https://instagram.com/cineroutes_car_rental?igshid=MzRlODBiNWFlZA=="
          target="_blank"
        >
          <Instagram sx={{ fontSize: '2rem', marginLeft: '0.5rem' }} />
        </Link>
      </Stack>


      <Stack direction="column" spacing={0} alignItems="center" sx={{ my: 8 }}>
        <Link
          sx={{ color: 'white' }}
          href="https://www.expeditieaardbol.nl/auto-huren-madagascar/"
          target="_blank"
        >
          Expeditie aardbol
        </Link>
        <Link
          sx={{ color: 'white' }}
          href="https://ctheworld.nl/zelfstandig-reizen-madagaskar/"
          target="_blank"
        >
          https://ctheworld.nl/zelfstandig-reizen-madagaskar/
        </Link>
      </Stack>

      <Box
        component="footer"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 2.25,
          backgroundColor: '#ffffff03',
        }}
      >
        Copyright © - All Right Reserved - cineroutes - last update: 12/2024
      </Box>
    </>
  );
};

export default Footer;
