import { createRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import InView from '../common/InView';
import carRentalImg1 from '../../assets/images/car-rental-img1.jpeg';
import carRentalImg2 from '../../assets/images/car-rental-img2.jpeg';
import carRentalNissanImg from '../../assets/images/car-rental-nissan-img.jpeg';
import carRentalHyundaiImg from '../../assets/images/car-rental-hyundai-galloper.jpeg';
import carRentalLandCruiserLJ79Img from '../../assets/images/car-rental-land-cruiser-lj79.jpg';
import carRentalRenault4SinparImg from '../../assets/images/car-rental-renault4-sinpar.jpeg';
import carRentalRenault4ColorsImg from '../../assets/images/car-rental-renault4-colors.jpeg';
import carRentalToyotaLandCruiserImg from '../../assets/images/car-rental-toyota-land-cruiser.jpeg';
import carRentalGalloper2Img from '../../assets/images/car-rental-galloper.jpeg';
import carRentalToyota4Img from '../../assets/images/carRentalToyota4.jpg';
import { Box, Grid, Stack, Typography } from '@mui/material';
import GlowingTitle from '../common/GlowingTitle';
import { leToBr } from '../../utils';

const CarRentalSection: FC<SectionProps> = ({ onEnter }) => {
  const { t } = useTranslation();
  const carRentalRef = createRef<HTMLElement>();
  const carList: Car[] = [
    {
      title: t('home.car-rental.nissan-np300'),
      price: 90,
      img: carRentalNissanImg,
    },
    {
      title: t('home.car-rental.toyota-land-cruiser'),
      price: 85,
      img: carRentalToyotaLandCruiserImg,
    },
    {
      title: t('home.car-rental.hyundai-galloper'),
      price: 85,
      img: carRentalHyundaiImg,
    },
    {
      title: 'HYUNDAI GALLOPER 2 - 4x4\n5 Doors',
      price: 90,
      img: carRentalGalloper2Img,
    },
    {
      title: t('home.car-rental.land-cruiser-lj79'),
      price: 90,
      img: carRentalLandCruiserLJ79Img,
    },
    {
      title: t('home.car-rental.renault4-sinpar'),
      price: 60,
      img: carRentalRenault4SinparImg,
    },
    {
      title: t('home.car-rental.renault4-colors'),
      price: 50,
      img: carRentalRenault4ColorsImg,
    },
    {
      title: t('home.car-rental.toyota-lc76'), // Utilise la clé pour récupérer le titre avec "5 Doors"
      price: 120,
      img: carRentalToyota4Img,
    },
  ];

  return (
    <InView targetRef={carRentalRef} onEnter={() => onEnter?.('car-rental')}>
      <Box
        ref={carRentalRef}
        id="car-rental"
        component="section"
        sx={(theme) => ({
          my: 10,
          [theme.breakpoints.down('lg')]: {
            my: 4,
          },
        })}
      >
        <Stack
          direction={{ lg: 'row', xs: 'column' }}
          spacing={{ lg: 8, xs: 4 }}
          sx={(theme) => ({
            maxWidth: '80vw',
            mx: 'auto',
            '&>*': {
              width: '100%',
            },
            [theme.breakpoints.down('lg')]: {
              maxWidth: 'none',
              p: 4,
            },
          })}
        >
          <Stack
            direction="column"
            sx={{
              '& #car-rental-img': {
                mb: 4,
              },
            }}
          >
            <GlowingTitle
              sx={(theme) => ({
                ml: 10,
                mb: 6,
                textTransform: 'uppercase',
                [theme.breakpoints.down('lg')]: {
                  ml: 0,
                },
              })}
              glowingBackgroundSx={{
                width: 50,
                height: 165,
                left: '35%',
              }}
            >
              {t('home.car-rental.title')}
            </GlowingTitle>
            <Stack direction="row" id="car-rental-img">
              <img
                src={carRentalImg1}
                alt="Road trip"
                style={{ width: '60%', aspectRatio: '16/9' }}
              />
              <img src={carRentalImg2} alt="SUVs" style={{ width: '40%' }} />
            </Stack>
            <Typography>
              {leToBr(t('home.car-rental.paragraph-one'))}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Box
              sx={(theme) => ({
                backgroundColor: '#004163',
                py: 2,
                px: 6,
                position: '',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  backgroundColor: '#0095B4',
                  width: 50,
                  height: 100,
                  bottom: 0,
                  right: theme.spacing(3),
                  transform: 'translateY(50%)',
                  filter: 'blur(20px)',
                  [theme.breakpoints.down('md')]: {
                    right: 0,
                    opacity: 0.6,
                  },
                },
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  backgroundColor: 'white',
                  width: 34,
                  height: 60,
                  bottom: 0,
                  right: theme.spacing(4),
                  transform: 'translateY(50%)',
                  [theme.breakpoints.down('md')]: {
                    right: theme.spacing(1),
                    width: 28,
                    height: 50,
                  },
                },
                [theme.breakpoints.down('md')]: {
                  px: 4,
                },
              })}
            >
              <Typography
                component="p"
                variant="h6"
                sx={{ fontWeight: 'bold' }}
              >
                {t('home.car-rental.hint')}
              </Typography>
            </Box>
            <Box
  sx={(theme) => ({
    backgroundColor: '#0095B4bb',
    p: 4,
    mb: 8,
    [theme.breakpoints.down('lg')]: {
      ml: 0,
    },
  })}
>
  <Typography sx={{ mb: 2.5 }}>
    {leToBr(t('home.car-rental.paragraph-two'))}
  </Typography>
  <Typography>
    <span
      dangerouslySetInnerHTML={{
        __html: t('home.car-rental.paragraph-three'),
      }}
      style={{ color: 'yellow' }} // Appliquer la couleur rouge au texte de paragraph-three
    />
  </Typography>
</Box>

          </Stack>
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          sx={(theme) => ({
            maxWidth: 1320,
            mx: 'auto',
            mt: 8,
            px: 8,
            [theme.breakpoints.down('lg')]: {
              px: 4,
            },
          })}
        >
          <Grid container spacing={{ lg: 15, md: 6, xs: 2 }} sx={{ mb: 7.5 }}>
            {carList.map(({ title, price, img }, i) => (
              <Grid
                key={i}
                item
                md={6}
                xs={12}
                sx={(theme) => ({
                  '& img': {
                    display: 'block',
                    width: '100%',
                    aspectRatio: '16/9',
                    mb: 2,
                  },
                })}
              >
                {Array.isArray(img) ? (
                  <Stack
                    direction="row"
                    sx={{ '&>img': { width: `${100 / img.length}%` } }}
                  >
                    {img.map((img, i) => (
                      <img key={i} src={img} alt={title} />
                    ))}
                  </Stack>
                ) : (
                  <img src={img} alt={title} />
                )}
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography variant="body2">
                      {t('home.car-rental.characteristics')} :
                    </Typography>
                    <Typography variant="h6" component="p">
                      {leToBr(title)}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body2">
                      {t('home.car-rental.price')}:
                    </Typography>
                    <Typography
                      color="white"
                      variant="h4"
                      component="p"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {price.toString()}€
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Box>
    </InView>
  );
};

export default CarRentalSection;
