import React, { FC, createRef } from 'react';
import InView from '../common/InView';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import heroBackground from '../../assets/images/renault4-landing.jpeg';
import CoolImg from '../../assets/images/cool.jpeg';
import joyImg from '../../assets/images/joy.jpeg';
import freedomImg from '../../assets/images/freedom.jpeg';
import bold1Img from '../../assets/images/bold-1.jpeg';
import bold2Img from '../../assets/images/bold-2.jpeg';
import bold3Img from '../../assets/images/bold-3.png';
import resilient1Img from '../../assets/images/resilient-1.jpeg';
import resilient2Img from '../../assets/images/resilient-2.jpeg';
import noLimit1Img from '../../assets/images/no-limit-1.jpeg';
import noLimit2Img from '../../assets/images/no-limit-2.jpeg';
import forestImg from '../../assets/images/forest.jpeg';
import caredOf1Img from '../../assets/images/cared-of-1.jpeg';
import caredOf2Img from '../../assets/images/cared-of-2.jpeg';
import caredOf3Img from '../../assets/images/cared-of-3.jpeg';
import caredOf4Img from '../../assets/images/cared-of-4.jpeg';
import riceFieldImg from '../../assets/images/rice-field.jpeg';
import horseImg from '../../assets/images/horse.jpeg';
import townImg from '../../assets/images/town.jpeg';
import parachuteImg from '../../assets/images/parachute.jpeg';
import { leToBr } from '../../utils';

const Renault4: FC<SectionProps> = ({ onEnter }) => {
  const { t } = useTranslation();
  const renault4Ref = createRef<HTMLElement>();

  return (
    <InView
      targetRef={renault4Ref}
      onEnter={() => onEnter?.('special-renault-4')}
    >
      <Stack
        id="renault4"
        ref={renault4Ref}
        direction="column"
        component="section"
      >
        <Box
          component="section"
          id="special-renault-4"
          ref={renault4Ref}
          sx={{
            minHeight: '100vh',
            maxWidth: '100%',
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: "''",
              zIndex: -1,
              display: 'block',
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${heroBackground})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '30%',
            },
          }}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              mx: 'auto',
              my: 6,
              px: 5,
              py: 2,
              [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(22),
                px: 4,
                py: 1,
              },
            })}
          >
            {t('home.renault4.title.blue1').toUpperCase()}{' '}
            <span style={{ color: 'white' }}>
              {t('home.renault4.title.white').toUpperCase()}
            </span>{' '}
            {t('home.renault4.title.blue2').toUpperCase()}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            pl: 30,
            pr: 6,
            mt: 5,
            mb: 6,
            [theme.breakpoints.down('lg')]: {
              px: 14,
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
        >
          <Typography sx={{ textAlign: 'center' }}>
            {t('home.renault4.subtitle')}
          </Typography>
          <Typography>{leToBr(t('home.renault4.description'))}</Typography>
        </Box>
        <Box
          component="div"
          sx={(theme) => ({
            position: 'relative',
            px: 30,
            mb: 2,
            [theme.breakpoints.down('lg')]: {
              px: 14,
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
        >
          <Box
            component="img"
            src={CoolImg}
            alt="renault 4 - Girl"
            sx={(theme) => ({
              width: '100%',
            })}
          />
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              top: 0,
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              ml: 5,
              my: 6,
              px: 16,
              py: 1,
              [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(32),
                px: 4,
                py: 1,
              },
            })}
          >
            {t('home.renault4.cool').toUpperCase()}
          </Typography>
        </Box>
        <Typography
          sx={(theme) => ({
            px: 30,
            mt: 2,
            mb: 3,
            [theme.breakpoints.down('lg')]: {
              px: 14,
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
        >
          {t('home.renault4.cool-description')}
        </Typography>
        

        <Box
          component="div"
          sx={(theme) => ({
            position: 'relative',
            px: 30,
            mb: 2,
            [theme.breakpoints.down('lg')]: {
              px: 14,
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
        >
          <Box
            component="img"
            src={joyImg}
            alt="Girl running barefoot on the grass, Renault 4 on background"
            sx={(theme) => ({
              width: '100%',
            })}
          />
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              bottom: theme.typography.pxToRem(150),
              right: theme.typography.pxToRem(350),
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              ml: 5,
              my: 6,
              px: 16,
              [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(32),
                bottom: 20,
                right: 80,
                px: 4,
                py: 0,
              },
            })}
          >
            {t('home.renault4.joy').toUpperCase()}
          </Typography>
        </Box>
        {/* <Typography
          sx={(theme) => ({
            fontWeight: 'bold',
            mx: 'auto',
            mb: 5,
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(25),
          })}
        >
          {leToBr(t('home.renault4.price.renault4'))}
        </Typography>
        <Typography
          sx={(theme) => ({
            fontWeight: 'bold',
            mx: 'auto',
            mb: 5,
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(25),
          })}
        >
          {leToBr(t('home.renault4.price.driver'))}
        </Typography> */}

        <Box
          component="div"
          sx={(theme) => ({
            position: 'relative',
            px: 30,
            mb: 2,
            [theme.breakpoints.down('lg')]: {
              px: 14,
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
        >
          <Box
            component="img"
            src={freedomImg}
            alt="Man jumping in front of renault 4"
            sx={(theme) => ({
              width: '100%',
            })}
          />
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              top: 0,
              left: '50%',
              translate: '-50% 0%',
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              px: 12,
              [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(32),
                px: 4,
                py: 0,
              },
            })}
          >
            {t('home.renault4.freedom').toUpperCase()}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            px: 30,
            mb: 6,
            textAlign: 'right',
            [theme.breakpoints.down('md')]: { px: 4 },
          })}
        >
          
        </Box>
        <Grid
          container
          xs={12}
          sx={(theme) => ({
            px: 5,
            mb: 5,
            position: 'relative',
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
          spacing={1}
        >
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              bottom: 0,
              left: '50%',
              translate: '-50% 0%',
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              px: 12,
              [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(32),
                px: 4,
                bottom: '37%',
              },
            })}
          >
            {t('home.renault4.bold').toUpperCase()}
          </Typography>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={bold1Img}
              alt="yellow renault 4"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box
              component="img"
              src={bold2Img}
              alt="yellow renault 4"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={5} md={3}>
            <Box
              component="img"
              src={bold3Img}
              alt="yellow renault 4"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
        </Grid>
        <Box
          sx={(theme) => ({
            px: 30,
            mb: 6,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: { px: 4 },
          })}
        >
          
        </Box>

        <Grid
          container
          xs={12}
          sx={(theme) => ({
            px: 25,
            mb: 5,
            position: 'relative',
            [theme.breakpoints.down('md')]: { px: 4 },
          })}
          spacing={1}
        >
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              bottom: 0,
              left: '50%',
              translate: '-50% 0%',
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              px: 10,
              [theme.breakpoints.down('md')]: {
                fontSize: theme.typography.pxToRem(32),
                bottom: '47%',
                px: 4,
                py: 0,
              },
            })}
          >
            {t('home.renault4.resilient').toUpperCase()}
          </Typography>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={resilient2Img}
              alt="red renault 4 - water"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={resilient1Img}
              alt="red renault 4 - tree"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
        </Grid>
        

        <Box
          component="img"
          src={noLimit1Img}
          alt="cars"
          sx={(theme) => ({
            px: 20,
            mb: 2,
            height: '100%',
            objectFit: 'cover',
            maxWidth: '100%',
            [theme.breakpoints.down('md')]: { px: 4 },
          })}
        />

        <Box
          component="div"
          sx={(theme) => ({
            position: 'relative',
            px: 20,
            mb: 2,
            [theme.breakpoints.down('lg')]: {
              px: 14,
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
          })}
        >
          <Box
            component="img"
            src={noLimit2Img}
            alt="cars"
            sx={(theme) => ({
              height: '100%',
              objectFit: 'cover',
              maxWidth: '100%',
            })}
          />
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              bottom: 5,
              left: '50%',
              translate: '-50% 0%',
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              px: 20,
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(30),
                bottom: '93%',
                px: 4,
              },
            })}
          >
            {t('home.renault4.no-limit').toUpperCase()}
          </Typography>
        </Box>

        <Box
          component="img"
          src={forestImg}
          alt="cars"
          sx={(theme) => ({
            px: 30,
            mt: 10,
            mb: 5,
            height: '100%',
            objectFit: 'cover',
            maxWidth: '100%',
            [theme.breakpoints.down('md')]: {
              mt: 1,
              mb: 2,
            },
          })}
        />
        <Box
          sx={(theme) => ({
            px: 30,
            mb: 6,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: { px: 4 },
          })}
        >
         
          
        </Box>

        <Grid
          container
          xs={12}
          sx={(theme) => ({
            px: 25,
            mb: 5,
            position: 'relative',
            [theme.breakpoints.down('md')]: { px: 4, mb: 2 },
          })}
          spacing={1}
        >
          <Typography
            sx={(theme) => ({
              position: 'absolute',
              zIndex: 1,
              top: '34%',
              left: '50%',
              translate: '-50% 0%',
              fontSize: theme.typography.pxToRem(56),
              color: '#15b0ef',
              fontWeight: 'bold',
              width: 'fit-content',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              px: 10,
              [theme.breakpoints.down('md')]: {
                px: 2,
                fontSize: theme.typography.pxToRem(28),
                top: '46%',
              },
            })}
          >
            {t('home.renault4.cared-for').toUpperCase()}
          </Typography>
          <Grid item xs={6} md={4}>
            <Box
              component="img"
              src={caredOf1Img}
              alt="red renault 4 - water"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Box
              component="img"
              src={caredOf2Img}
              alt="red renault 4 - tree"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Box
              component="img"
              src={caredOf3Img}
              alt="red renault 4 - tree"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <Box
              component="img"
              src={caredOf4Img}
              alt="red renault 4 - tree"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          sx={(theme) => ({
            px: 25,
            [theme.breakpoints.down('md')]: { px: 4, mt: 5 },
          })}
          spacing={1}
        >
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={riceFieldImg}
              alt="rice field"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              component="img"
              src={horseImg}
              alt="horse"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              component="img"
              src={townImg}
              alt="town"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={parachuteImg}
              alt="parachute"
              sx={(theme) => ({
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100%',
              })}
            />
          </Grid>
        </Grid>
      </Stack>
    </InView>
  );
};

export default Renault4;
